<template>
    <ValidationObserver v-slot="{ handleSubmit,reset }" ref="form">
        <form class="form" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div class="wizard-label">
                                    <i class="wizard-icon flaticon-bus-stop"></i>
                                    <h3 class="wizard-title">Chuyển từ</h3>
                                </div>
                                <span class="svg-icon svg-icon-xl wizard-arrow">
                                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                        </g>
                                    </svg>
                                 <!--end::Svg Icon-->
                                </span>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <div class="move-from">
                                <div class="form-group">
                                    <label>Chọn nhân sự
                                        <span class="text-danger">*</span></label>
                                    <ValidationProvider name="Nhân sự" rules="required" v-slot="{ errors,classes }">
                                    <Select2Ajax minimumInputLength="6" v-model="sale" placeholder="Nhập mã nv/SĐT/email" method="GET" :url="getUserUrl"  @input="showBranch"></Select2Ajax>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group fv-plugins-icon-container">
                                    <label>Văn phòng đi</label>
                                    <input type="text" class="form-control" disabled v-model="vpdi"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div class="wizard-label">
                                    <i class="wizard-icon flaticon-bus-stop"></i>
                                    <h3 class="wizard-title">Chuyển đến</h3>
                                </div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <div class="form-group fv-plugins-icon-container">
                                <label>Khu vực</label>
                                <!--<ValidationProvider name="Khu vực" rules="required" v-slot="{ errors,classes }">-->
                                    <select2 :options="areas" v-model="cacheArea" placeholder="Chọn khu vực" @input="changeArea($event)"></select2>
                                    <!--<div class="fv-plugins-message-container">-->
                                        <!--<div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>-->
                                    <!--</div>-->
                                <!--</ValidationProvider>-->
                            </div>
                            <div class="form-group fv-plugins-icon-container">
                                <label>Văn phòng đến<span class="text-danger">*</span></label>
                                <ValidationProvider name="Văn phòng đến" vid="branch_id" rules="required" v-slot="{ errors,classes }">
                                    <select2 :options="branches" v-model="branchDen" @input="changeBranch" placeholder="Chọn văn phòng"></select2>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                        <button
                                type="submit"
                                class="btn btn-success mr-2"
                                :disabled="loading"
                        >
                            Chuyển
                        </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import Select2 from "@/view/content/Select2";
    import Select2Ajax from "@/view/content/Select2Ajax";
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
    import {GET_AREAS,GET_AREA_BY_ID} from "@/core/services/store/center/area.module";
    import {GET_USER} from '@/core/services/ajaxUrl';
    import ApiService from "@/core/services/api.service";
    const _ = require('lodash');

    export default {
        components: {
            Select2,Select2Ajax
        },
        data: () => ({
            vpdi: '',
            cacheSale:'',
            cacheArea:'',
            cacheBranch:'',
            branchDen: '',
            branches : [],
            branchesByArea : [],
            sales: [],
            sale: '',
            loading: false,
            areas: [],
        }),
        mounted() {
            this.getAreas();
            this.getBranches();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự"},
                {title: "Khối cơ sở"},
                {title: "Điều chuyển nhân sự"}
            ]);
        },
        computed: {
            getUserUrl: function () {
                return GET_USER
            }
        },
        methods: {
            getAreas() {
                this.$store.dispatch(GET_AREAS, {
                    page: this.page,
                    per_page: 50
                }).then((res) => {
                    this.areas = _.map(res.data.data,(item) => {
                        item = {id: item.id, text: item.name};
                        return item;
                    })
                });
            },
            changeArea : function ($event){
              let area_id = $event != null ? $event : '';
              if (area_id){
                this.branches = _.reduce(this.branchesByArea, function (result,value,key){
                  if (value.area_id === parseInt(area_id)){
                    result.push(value);
                  }
                  return result
                },[]);
                this.branchDen = 0;
              }else{
                this.branches = this.branchesByArea;
                this.branchDen = '';
              }
            },
            showBranch(value) {
                if(value){
                    ApiService.query('branches/get-by-user', {id:value})
                        .then(({data}) => {
                            this.vpdi = data.data.name_alias ? data.data.name_alias : data.data.name
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }else{
                    this.vpdi = '';
                }
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES,{limit: 10000, status : 1}).then((res)=>{
                    this.branchesByArea = _.map(res.data.data,(item) => {
                        item = {id: item.id, text: item.name_alias ? item.name_alias : item.name, area_id : item.area_id};
                        return item;
                    });
                    this.branches = this.branchesByArea;
                });
            },
          changeBranch : function (branch_id){
            this.branchDen =  branch_id ? branch_id : 0;
            if (this.branchDen === 0){
              this.$refs.form.setErrors({branch_id : ['Văn phòng đến không được để trống']});
            }
          },
            onSubmit() {
              if (this.branchDen === 0 ){
                this.$refs.form.setErrors({branch_id : ['Văn phòng đến không được để trống']});
              }else{
                this.loading = true;
                ApiService.post('sale/move', {
                  sale: this.sale,
                  branch: this.branchDen
                }).then(({data}) => {
                  this.$refs.form.reset();
                  this.$bvToast.toast(data.message, {
                    title: 'Thành công',
                    variant: 'success',
                    solid: true
                  });
                  this.loading = false;
                  this.sale = '';
                  this.cacheArea = '';
                  this.branchDen = 0;
                  // window.location.reload();
                })
                    .catch(err => {
                      this.loading = false;
                      console.log(err);
                    });
              }
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .wizard-step {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .wizard-step .wizard-label {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
    .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon {
        color: #3699FF;
    }
    .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
        color: #3699FF;
    }
    .wizard-step .wizard-label .wizard-icon {
        color: #B5B5C3;
        font-size: 2.75rem;
    }
    .wizard-arrow{
        position: absolute;
        right: 0;
    }
    .wizard-arrow svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
        fill: #3699FF;
    }
    .select2-selection--single{
        height: 40px;
    }
</style>



